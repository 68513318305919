export const extractParamHref = (href, name) => {
    let param = extractParamsHref(href).find(e => e.key === name);
    if (param != null) {
        return param.value;
    }
    return null;
}

export const extractParamsHref = href => {
    let queryParams = href.split('#')[1];
    let result = [];
    if (queryParams == null) {
        return result;
    }
    queryParams.split('&').forEach(e => {
        let pair = e.split('=');
        result.push({key: pair[0].replace('/', ''), value: pair[1]});
    });
    return result;
}
