import * as sdk from 'matrix-js-sdk';

export const loginByPassword = async (baseUrl, user, password) => {
    const client = sdk.createClient({
        baseUrl
    });

    return await client.login('m.login.password', {
        identifier: {
            type: 'm.id.user',
            user,
        },
        password,
        type: 'm.login.password',
    });
}

export const loginByToken = async (baseUrl, token) => {
    const client = sdk.createClient({
        baseUrl
    });

    return await client.login('m.login.token', {
        token,
        type: 'm.login.token'
    });
}