import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class RoomChat extends BElement {
    view() {
        const room = getMatrixClient().getRoom(this.getAttribute('roomId'));
        this.getAttribute('eventId')
        if (!room) {
            throw Error('Room not found');
        }

        const div = document.querySelector('.messages');
        const isScrolledToBottom = (div && div.scrollHeight - div.scrollTop === div.clientHeight) || div === null;

        if (isScrolledToBottom) {
            setTimeout(() => this.testScrollPosition(), 100);
        }

        const events = [];
        room.getLiveTimeline()
            .getTimelineSet()
            .getTimelines()
            .forEach(timeline => timeline.getEvents()
                .forEach((event) => events.push(event.event)));

        return html`
            <div class='chat-container'>
                <div class='messages' id='messages'>
                    ${events.map(
                            event => html`
                                <kosyma-room-chat-messsage event=${JSON.stringify(event)}></kosyma-room-chat-messsage>
                            `
                    )}
                </div>
                <kosyma-room-chat-input-field></kosyma-room-chat-input-field>
            </div>
        `;
    }

    testScrollPosition = () => {
        const div = document.querySelector('.messages');
        if (div) {
            div.scrollTo({
                top: div.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

}

customElements.define('kosyma-room-chat', RoomChat);