import {html} from 'lit-html';
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js';
import {formatTimestamp} from '../../time/control/Converter.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class RoomList extends BElement {

    view() {
        const rooms = getMatrixClient().getRooms();
        rooms.sort((a, b) => {
            if (a.getLastActiveTimestamp() > b.getLastActiveTimestamp()) {
                return -1;
            }
            if (a.getLastActiveTimestamp() < b.getLastActiveTimestamp()) {
                return 1;
            }
            return 0;
        });
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Rooms</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Members</th>
                            <th>Last Update</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${rooms.map(e => html`
                            <tr>
                                <td>${e.name}</td>
                                <td>${Object.keys(e.currentState.members).map(e => html`
                                    <div>${e}</div>`)}
                                </td>
                                <td>${formatTimestamp(e.getLastActiveTimestamp())}</td>
                                <td>
                                    <kosyma-button
                                            identifier='Open'
                                            label='Open'
                                            @click=${() => Router.go('/messenger/rooms/' + e.roomId)}
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                </div>
            </div>
        `;
    }
}

customElements.define('kosyma-room-list', RoomList);