import {createAction} from '@reduxjs/toolkit';
import store from '../../store.js';

export const roomCreateAction = createAction('roomCreateAction')
export const setRoomCreateState = state => {
    store.dispatch(roomCreateAction(state));
}

export const roomAction = createAction('roomAction')
export const setRoomState = state => {
    store.dispatch(roomAction(state));
}

export const resetAction = createAction('resetAction')
export const roomResetState = _ => {
    store.dispatch(resetAction());
}

export const roomChatAction = createAction('roomChatAction')
export const setRoomChatState = state => {
    store.dispatch(roomChatAction(state));
}

export const roomMembersAction = createAction('roomMembersAction')
export const roomMembersState = state => {
    store.dispatch(roomMembersAction(state));
}

export const roomInvitationAction = createAction('roomInvitationAction')
export const setRoomInvitationState = state => {
    store.dispatch(roomInvitationAction(state));
}

export const roomVzdSearchAction = createAction('roomFhirSearchAction')
export const setRoomVzdSearchState = state => {
    store.dispatch(roomVzdSearchAction(state));
}
