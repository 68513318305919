import {createReducer} from '@reduxjs/toolkit'
import {
    addDeviceVerificationAction,
    baseUrlAction,
    changeServerAction,
    matrixAction,
    matrixUpdateAction, resetAction,
    sessionAction
} from '../control/MatrixControl.js'

const initialState = {
    client_started: false,
    base_url: 'https://int-alice.ujumbelabs.com',
    last_event_time: null,
    session: null,
    invitations: [],
    device_verification_requests: [],
    change_server: {
        url: '',
    },
    server_type: 'matrix'
}
export const matrix = createReducer(initialState, (builder) => {
    builder.addCase(baseUrlAction, (state, {payload}) => {
        state.base_url = payload
    }).addCase(sessionAction, (state, {payload}) => {
        state.session = payload
    }).addCase(matrixAction, (state, {payload}) => {
        state[payload.name] = payload.value
    }).addCase(matrixUpdateAction, (state, {payload}) => {
        state.last_event_time = Date.now()
    }).addCase(addDeviceVerificationAction, (state, {payload}) => {
        state.device_verification_requests = payload
    }).addCase(changeServerAction, (state, {payload}) => {
        state.change_server[payload.name] = payload.value
    }).addCase(resetAction, (state, {payload}) => {
        const base_url = state.base_url;
        Object.assign(state, initialState);
        state.base_url = base_url;
    });
})
