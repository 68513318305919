import {html} from 'lit-html';
import BElement from '../../BElement.js';

class Container extends BElement {
   view() {
      return html ``
   }
}

customElements.define('kosyma-container', Container);
