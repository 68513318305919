import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'

export const clearRegisterAction = createAction('clearRegisterAction')
export const clearRegisterState = _ => {
    store.dispatch(clearRegisterAction())
}

export const passwordLoginAction = createAction('passwordLoginAction')
export const setPasswordLoginState = state => {
    store.dispatch(passwordLoginAction(state))
}

export const orgAdminLoginAction = createAction('orgAdminLoginAction')
export const setOrgAdminLoginState = state => {
    store.dispatch(orgAdminLoginAction(state))
}
