import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/Home.css';

class Home extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix
        }
    }

    view() {
        return html`
            <div class='main-content'>
                <div class='header'>
                    <h1>Welcome ${this.state.matrix.session.user_id}</h1>
                </div>
                <div class='cards'>
                    <kosyma-home-invitations></kosyma-home-invitations>
                    <div class='card'>
                        <h3>New Room Messages</h3>
                        <p>adfadfa.</p>
                    </div>
                    <div class='card'>
                        <h3>Kosyma News</h3>
                        <p>adfadfa.</p>
                    </div>
                    <div class='card'>
                        <h3>App Updates</h3>
                        <p>adfadfa.</p>
                    </div>
                </div>
            </div>`
    }
}

customElements.define('kosyma-home', Home);
