const secretStorageKeys = new Map();

export const storePrivateKey = (keyId, privateKey) => {
    if (!(privateKey instanceof Uint8Array)) {
        throw new Error('Invalid privateKey. Storage failed.');
    }
    secretStorageKeys.set(keyId, privateKey);
};

export const hasPrivateKey = (keyId) =>
    secretStorageKeys.has(keyId) && secretStorageKeys.get(keyId) instanceof Uint8Array;

export const getPrivateKey = (keyId) => secretStorageKeys.get(keyId);

export const deletePrivateKey = (keyId) => {
    secretStorageKeys.delete(keyId);
};

export const clearSecretStorageKeys = () => secretStorageKeys.clear();

export const getSecretStorageKey = async ({keys}) => {
    const keyId = Object.keys(keys).find(hasPrivateKey);
    if (!keyId) {
        return undefined;
    }

    const privateKey = getPrivateKey(keyId);
    return [keyId, privateKey];
};

export const cacheSecretStorageKey = (keyId, keyInfo, privateKey) => {
    storePrivateKey(keyId, privateKey);
};

export const cryptoCallbacks = {
    getSecretStorageKey,
    cacheSecretStorageKey,
};
