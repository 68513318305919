import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {build} from '../../version.js';
import '../css/footer.css'


class Footer extends BElement {
    view() {
        return html`
            <footer class='footer'>
                <div class='footer-container'>
                    <div class='footer-column'>
                        <h3>Über uns</h3>
                        <p>Informationen über TIM.</p>
                    </div>
                    <div class='footer-column'>
                        <h3>Kontakt</h3>
                        <p>Email: info@kosyma.com</p>
                        <p>Telefon: +49 123 456 789</p>
                    </div>
                    <div class='footer-column'>
                        <h3>Folgen Sie uns</h3>
                        <p><a href='#'>Facebook</a></p>
                        <p><a href='#'>Twitter</a></p>
                        <p><a href='#'>Instagram</a></p>
                    </div>
                </div>
                <div class='footer-bottom'>
                    <p>&copy; 2024 Kosyma GmbH. Alle Rechte vorbehalten.</p>
                    <p>Build ${build.time}</p>
                </div>
            </footer>`
    }
}

customElements.define('kosyma-footer', Footer);
