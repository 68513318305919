import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'

export const resetAction = createAction('resetAction')
export const resetState = state => {
    store.dispatch(resetAction(state));
}

export const sessionAction = createAction('sessionAction')
export const setSessionState = state => {
    store.dispatch(sessionAction(state));
}

export const baseUrlAction = createAction('baseUrlAction')
export const setBaseUrlState = state => {
    store.dispatch(baseUrlAction(state));
}

export const matrixAction = createAction('matrixAction')
export const setMatrixState = state => {
    store.dispatch(matrixAction(state));
}

export const addDeviceVerificationAction = createAction('addDeviceVerificationAction')
export const addDeviceVerificationState = state => {
    store.dispatch(addDeviceVerificationAction(state));
}

export const matrixUpdateAction = createAction('matrixUpdateAction')
export const setMatrixUpdateState = _ => {
    store.dispatch(matrixUpdateAction());
}

export const changeServerAction = createAction('changeServerAction')
export const setChangeServerState = state => {
    store.dispatch(changeServerAction(state));
}
