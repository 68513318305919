import {nothing} from 'lit-html'
import BElement from '../../BElement.js'
import {getMatrixClient} from '../control/MatrixClient.js';
import {addDeviceVerificationState, setMatrixState, setMatrixUpdateState} from '../control/MatrixControl.js';

class Matrix extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
        }
    }

    view() {
        if (getMatrixClient() && !this.state.matrix.client_started) {
            this.onStartClient().then(r => setMatrixState({name: 'client_started', value: true}));
        }
        return nothing;
    }

    onStartClient = async () => {
        console.log('Starting Client');
        await getMatrixClient().startClient({
            initialSyncLimit: 40,
            lazyLoadMembers: true
        });
        getMatrixClient().once('sync', (state, prevState, res) => {
        });
        getMatrixClient().on('event', async event => {
            setMatrixUpdateState();
        });
        getMatrixClient().on('RoomMember.typing', (event, member) => {
            setMatrixUpdateState();
        });
        getMatrixClient().on('toDeviceEvent', async event => {
                if (event.event.type.startsWith('m.key.verification')) {
                    console.log('m.key.verification event received:', event);
                }

                if (event.event.type === 'm.key.verification.request'
                    || event.event.type === 'm.key.verification.ready') {
                    addDeviceVerificationState(event.event);
                    this.clearVerificationRequests();
                    this.addVerificationRequest(event);
                } else if (event.event.type === 'm.key.verification.cancel') {
                    addDeviceVerificationState(event.event);
                    this.addVerificationRequest(event);
                } else if (event.event.type === 'm.key.verification.start') {
                    addDeviceVerificationState(event.event);
                    this.addVerificationRequest(event);
                }
            }
        );
        getMatrixClient().on('Room.myMembership', (room, memberShip) => {
            const name = room.currentState.getStateEvents('m.room.name').pop().event.content.name;
            const creator = room.currentState.getStateEvents('m.room.create').pop().event.sender;
            const invitations = this.state.matrix.invitations.filter(e => e.room_id !== room.roomId);
            if (memberShip === 'invite') {
                invitations.push({room_name: name, room_id: room.roomId, room_creator: creator});
            }
            setMatrixState({name: 'invitations', value: invitations});
            setMatrixUpdateState();
        });
    }

    addVerificationRequest = ({verificationRequest}) => {
        if (!globalThis.verificationRequests) {
            globalThis.verificationRequests = [];
        }
        globalThis.verificationRequests.push(verificationRequest);
    }

    clearVerificationRequests = () => {
        globalThis.verificationRequests = null;
    }
}

customElements.define('kosyma-matrix', Matrix)
