import {html} from 'lit-html'
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js'
import {setRoomCreateState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {addMessageState} from '../../messages/control/MessagesControl.js';

class RoomCreate extends BElement {
    connectedCallback() {
        super.connectedCallback();
    }

    extractState(state) {
        return {
            room_create: state.rooms.room_create,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Create Room</h2>
                    <kosyma-input-text
                            identifier='name'
                            label='Name'
                            autofocus='true'
                            .value=${this.state.room_create.name}
                            @keyup=${(e) => setRoomCreateState({name: 'name', value: e.target.value})}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='topic'
                            label='Topic'
                            value=${this.state.room_create.topic}
                            @keyup=${(e) => setRoomCreateState({name: 'topic', value: e.target.value})}
                    >
                    </kosyma-input-text>
                    <kosyma-checkbox
                            identifier='encrypted'
                            label='Encrypted'
                            checked=${this.state.room_create.encrypted}
                            @change=${(e) => setRoomCreateState({name: 'encrypted', value: e.target.checked})}
                    >
                    </kosyma-checkbox>
                    <kosyma-checkbox
                            identifier='public'
                            label='Public'
                            checked=${this.state.room_create.public === 'public'}
                            @change=${(e) => {
                                setRoomCreateState({
                                    name: 'public',
                                    value: e.target.checked ? 'public' : 'private'
                                });
                            }}
                    >
                    </kosyma-checkbox>
                    <kosyma-checkbox
                            identifier='case_related'
                            label='Case Related'
                            checked=${this.state.room_create.case_related}
                    >
                    </kosyma-checkbox>
                    <kosyma-button
                            identifier='create'
                            label='Create'
                            @click=${e => this.onCreate(e)}
                    ></kosyma-button>
                </div>
            </div>
        `
    }

    onCreate = async (e) => {
        try {
            e.target.disabled = true;
            e.target.innerHTML = 'Please wait...';

            const createRoomResponse = await getMatrixClient().createRoom(this.state.room_create);
            while (!getMatrixClient().getRoom(createRoomResponse.room_id)) {
                await new Promise(resolve => {
                    getMatrixClient().once('Room', resolve);
                });
            }
            if (this.state.room_create.encrypted) {
                const cryptoConfig = {algorithm: 'm.megolm.v1.aes-sha2'};
                await getMatrixClient().sendStateEvent(createRoomResponse.room_id, 'm.room.encryption', cryptoConfig);
                await getMatrixClient().setRoomEncryption(createRoomResponse.room_id, cryptoConfig);
            }
            Router.go('/messenger/rooms/' + createRoomResponse.room_id);
        } catch (e) {
            console.error(e)
            addMessageState(e);
        }
    }
}

customElements.define('kosyma-room-create', RoomCreate);
