import {createReducer} from '@reduxjs/toolkit'
import {
    addMessageAction,
    openOpenTimestampAction,
    clearMessagesAction,
    removeMessagesAction
} from '../control/MessagesControl.js'

const initialState = {
    open_timestamp: 0,
    display_time: 4,
    messages: [],
}
export const messages = createReducer(initialState, (builder) => {
    builder.addCase(addMessageAction, (state, {payload}) => {
        state.messages.push(payload);
    }).addCase(clearMessagesAction, (state, {payload}) => {
        state.messages = [];
    }).addCase(removeMessagesAction, (state, {payload}) => {
        payload.forEach(m => {
            state.messages = state.messages.filter(item => item.time !== m.time);
        })
    }).addCase(openOpenTimestampAction, (state, {payload}) => {
        state.open_timestamp = state.open_timestamp = payload;
    });
})
