import {createAction} from '@reduxjs/toolkit';
import store from '../../store.js';

export const deviceListAction = createAction('deviceListAction')
export const setDeviceListState = state => {
    store.dispatch(deviceListAction(state));
}

export const deviceVerificationAction = createAction('deviceVerificationAction')
export const setDeviceVerificationState = state => {
    store.dispatch(deviceVerificationAction(state));
}
