import {createReducer} from '@reduxjs/toolkit'
import {
    deviceListAction, deviceVerificationAction,
} from '../control/DevicesControl.js'

const initialState = {
    list: {results: []},
    verification: {results: []}
}

export const devices = createReducer(initialState, (builder) => {
    builder.addCase(deviceListAction, (state, {payload}) => {
        state.list[payload.name] = payload.value
    }).addCase(deviceVerificationAction, (state, {payload}) => {
        state.verification[payload.name] = payload.value
    });
})
