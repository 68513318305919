import {html} from 'lit-html';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import BElement from '../../BElement.js';

class RoomAdmin extends BElement {
    view() {
        const room = getMatrixClient().getRoom(this.getAttribute('roomId'));

        return html`
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Field</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <kosyma-input-text
                                        readonly='true'
                                        identifier='ID'
                                        label='ID'
                                        name='ID'
                                        value=${room.roomId}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        `;
    }
}

customElements.define('kosyma-room-admin', RoomAdmin);
