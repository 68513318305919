import {html, nothing} from 'lit-html';
import {Router} from '@vaadin/router';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import BElement from '../../BElement';
import {setDeviceListState} from '../control/DevicesControl.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';
import {getDevices} from '../control/DevicesClient.js';

class DeviceList extends BElement {
    connectedCallback() {
        super.connectedCallback();
        getDevices().then(res => {
            setDeviceListState({name: 'results', value: res.reverse()});
        }).catch(err => addErrorMessage(err));
    }

    extractState(state) {
        return {
            device_id: state.matrix.session.device_id,
            list: state.devices.list,
            matrix: state.matrix,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Devices</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>User</th>
                            <th>Verified</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.list.results.map(e => html`
                            <tr>
                                <td>${e.device_id}</td>
                                <td>${e.display_name}</td>
                                <td>${e.user_id}</td>
                                <td>${e.is_verified}</td>
                                <td>
                                    <kosyma-button
                                            identifier='delete'
                                            label='Delete'
                                            @click=${() => this.onDelete(e.device_id)}
                                    ></kosyma-button>
                                    ${e.device_id === this.state.matrix.session.device_id ?
                                            html`
                                                <kosyma-button
                                                        identifier='verify'
                                                        label='Verify'
                                                        @click=${() => this.onVerify(e.device_id)}
                                                ></kosyma-button>`
                                            : nothing}
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                </div>
            </div>
        `;
    }

    onVerify = deviceId => {
        Router.go('/messenger/devices/verification/' + deviceId);
    }

    onDelete = async deviceId => {
        try {
            await getMatrixClient().deleteDevice(deviceId);
            const results = getDevices();
            setDeviceListState({name: 'results', value: results});
        } catch (err) {
            addErrorMessage(err);
        }
    };
}

customElements.define('kosyma-device-list', DeviceList)