import BElement from '../../BElement.js';
import {html, nothing} from 'lit-html';

class InitialsCircle extends BElement {
    view() {
        const name = this.getAttribute('name') || '';
        const initials = this.getInitials(name);

        return html`
            <div class='initials-circle'>${initials}</div>
        `;
    }

    getInitials = name => {
        if (name.startsWith('@')) {
            name = name.substring(1);
        }

        return name.split(' ').map(word => word[0]).join('').toUpperCase();
    }
}

customElements.define('kosyma-initials-circle', InitialsCircle);