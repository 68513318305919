import BElement from '../../BElement.js'
import {html} from 'lit-html'

class Button extends BElement {
    view() {
        const label = this.getAttribute('label')
        const identifier = this.getAttribute('identifier')
        const readonly = this.getAttribute('readonly')
        return html`
            <div class='form-group'>
                <button
                        class='form-button'
                        id=${identifier ? identifier : ''}
                        ?readonly=${(!!readonly)}
                        ?aria-readonly=${(!!readonly)}
                >
                    ${label}
                </button>
            </div>
        `
    }
}

customElements.define('kosyma-button', Button)
