import {configureStore} from '@reduxjs/toolkit'
import {matrix} from './matrix/entity/MatrixReducer.js'
import {login} from './login/entity/LoginReducer.js'
import {messages} from './messages/entity/MessagesReducer.js'
import {rooms} from './room/entity/RoomsReducer.js'
import {register} from './register/entity/RegisterReducer.js'
import {devices} from './devices/entity/DevicesReducer.js'
import {load} from './localstorage/control/StorageControl.js';

const reducer = {matrix, register, login, messages, rooms, devices}
//const preloadedState = load();
//const config = preloadedState ? {reducer, preloadedState} : {reducer};
const store = configureStore({reducer});
//const store = configureStore({reducer});

export default store;