export const vzdSearch = async (base_url, bearer, query) => {
    const response = await fetch(base_url + '/admin/v1/search/endpoint?contains=' + query, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + bearer
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(await response.text());
    }
}
