import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {setRoomVzdSearchState} from '../control/RoomControl.js';
import {vzdSearch} from '../control/VzdSearchClient.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class RoomVzdSearch extends BElement {
    constructor() {
        super();
        this.timeoutId = null;
    }

    extractState(state) {
        return {
            vzd_search: state.rooms.room.vzd_search, matrix: state.matrix
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('debounced-input', this.onDebouncedInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('debounced-input', this.onDebouncedInput);
    }

    view() {
        const roomId = this.getAttribute('roomId');

        return html`
            <div>
                <kosyma-input-text
                        identifier='filter'
                        label='Filter'
                        name='filter'
                        autofocus='true'
                        value=${this.state.vzd_search.filter}
                        @input=${e => this.onInputChange(e)}
                ></kosyma-input-text>
            </div>
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    ${this.state.vzd_search.results.map(e => html`
                        <tr>
                            <td>${e.name}</td>
                            <td>${e.address}</td>
                            <td>
                                <kosyma-button
                                        identifier='Invite'
                                        label='Invite'
                                        @click=${() => this.onInvite(roomId, e.address)}
                                ></kosyma-button>
                            </td>
                        </tr>
                    `)}
                    </tbody>
                </table>
            </div>
        `;
    }

    onInputChange = ({target: {name, value}}) => {
        setRoomVzdSearchState({name, value});
        this.debouncedUpdate(value);
    }

    onDebouncedInput = () => {
        vzdSearch(this.state.matrix.base_url,
            this.state.matrix.session.access_token,
            this.state.vzd_search.filter).then(r => setRoomVzdSearchState({
            'name': 'results',
            'value': r
        })).catch(e => addErrorMessage(e));
    }

    debounce = (func, wait) => {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => func.apply(this, args), wait);
        };
    }

    debouncedUpdate = this.debounce(value => {
        this.dispatchEvent(new CustomEvent('debounced-input', {
            detail: {value}
        }));
    }, 1000);

    onInvite = (roomId, userId) => {
        getMatrixClient().invite(roomId, userId).then(res => {
            addInformationMessage(userId + ' successfully invited.');
        }).catch(err => {
            addErrorMessage(err);
        });
    }
}

customElements.define('kosyma-room-vzd-search', RoomVzdSearch);
