import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'

export const addErrorMessage = text => {
    addMessageState({text, type: 'Error'});
}

export const addInformationMessage = text => {
    addMessageState({text, type: 'Information'});
}

export const addMessageAction = createAction('addMessageAction')
export const addMessageState = state => {
    state.time = Date.now();
    store.dispatch(addMessageAction(state))
}

export const clearMessagesAction = createAction('clearMessagesAction')
export const clearMessagesState = state => {
    store.dispatch(clearMessagesAction(state))
}

export const removeMessagesAction = createAction('removeMessagesAction')
export const removeMessagesState = state => {
    store.dispatch(removeMessagesAction(state))
}

export const openOpenTimestampAction = createAction('openOpenTimestampAction')
export const openOpenTimestampState = state => {
    store.dispatch(openOpenTimestampAction(state))
}
