import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {setRoomChatState} from '../control/RoomControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';

class RoomChatInputField extends BElement {
    extractState(state) {
        return {
            room: state.rooms.room,
            chat: state.rooms.room.chat,
        }
    }

    view() {
        return html`
            <div class='input-group'>
                <input type='text' placeholder='Type a message...'
                       autofocus='' autofocus'
                    .value='${this.state.chat.text}'
                    @keyup=${(e) => e.key === 'Enter' ? this.onSendMessage() :
                        setRoomChatState({
                            name: 'text',
                            value: e.target.value
                        })}/>

                <button @click=${this.onSendMessage}>Send</button>

                <button class='upload-btn' @click=${this.onOpenFileDialog}>Upload</button>
                <input type='file' class='fileInput' @change=${this.onUpload}
                       style='display: none;' accept='image/*'/>
            </div>
        `;
    }

    onSendMessage = () => {
        if (!this.state.chat.text || this.state.chat.text.trim().length === 0) {
            return;
        }

        const content = {
            body: this.state.chat.text, 'msgtype': 'm.text'
        };

        getMatrixClient().sendMessage(this.state.room.id, content).then(res => {
            console.log(res);
        }).catch(err => {
            addErrorMessage(err);
            console.error(err);
        });
        setRoomChatState({name: 'text', value: ''});
    }

    onOpenFileDialog = () => {
        document.querySelector('.fileInput').click();
    }

    onUpload = e => {
        const file = e.target.files[0]
        if (file.size < 1000000) {
            getMatrixClient().uploadContent(file).then(res => {
                getMatrixClient().sendMessage(this.state.room.id, {
                    'body': file.name, 'info': {
                        'size': file.size, 'mimetype': file.type
                    }, 'msgtype': 'm.file', 'm.mentions': {}, 'url': res.content_uri
                }).then(res => {
                    addInformationMessage(file.name + ' upload successfully');
                    e.target.value = '';
                    console.log(res);
                }).catch(err => {
                    addErrorMessage(err);
                    e.target.value = '';
                    console.error(err);
                });
            }).catch(err => {
                addErrorMessage(err);
                e.target.value = '';
                console.error(err);
            });
        } else {
            addErrorMessage('File to large');
            e.target.value = ''
            console.log('File to large');
        }
    }
}

customElements.define('kosyma-room-chat-input-field', RoomChatInputField);