import {html} from 'lit-html';
import {setRoomInvitationState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import BElement from '../../BElement.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';

class RoomInvitation extends BElement {
    constructor() {
        super();
        this.timeoutId = null;
    }

    extractState(state) {
        return {
            invitation: state.rooms.room.invitation,
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('debounced-input', this.onDebouncedInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('debounced-input', this.onDebouncedInput);
    }

    view() {
        const roomId = this.getAttribute('roomId');

        return html`
            <div>
                <kosyma-input-text
                        identifier='filter'
                        label='Filter'
                        name='filter'
                        autofocus='true'
                        value=${this.state.invitation.filter}
                        @input=${e => this.onInputChange(e)}
                ></kosyma-input-text>
            </div>
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    ${this.state.invitation.results.map(e => html`
                        <tr>
                            <td>${e.user_id}</td>
                            <td>
                                <kosyma-button
                                        identifier='Invite'
                                        label='Invite'
                                        @click=${() => this.onInvite(roomId, e.user_id)}
                                ></kosyma-button>
                            </td>
                        </tr>
                    `)}
                    </tbody>
                </table>
            </div>
        `;
    }

    onInputChange = ({target: {name, value}}) => {
        setRoomInvitationState({name, value});
        this.debouncedUpdate(value);
    }

    onDebouncedInput = () => {
        getMatrixClient()
            .searchUserDirectory({term: this.state.invitation.filter, limit: 100})
            .then(res => {
                setRoomInvitationState({name: 'results', value: res.results});
            })
            .catch(err => {
                addErrorMessage(err);
            });
    }

    debounce = (func, wait) => {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => func.apply(this, args), wait);
        };
    }

    debouncedUpdate = this.debounce(value => {
        this.dispatchEvent(new CustomEvent('debounced-input', {
            detail: {value}
        }));
    }, 1000);

    onInvite = (roomId, userId) => {
        getMatrixClient().invite(roomId, userId).then(res => {
            addInformationMessage(userId + ' successfully invited.');
        }).catch(err => {
            addErrorMessage(err);
        });
    }
}

customElements.define('kosyma-room-invitation', RoomInvitation);
