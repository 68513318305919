import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/messages.css'
import {clearMessagesState, removeMessagesState} from '../control/MessagesControl.js';

class Messages extends BElement {
    extractState(state) {
        return {
            messages: state.messages
        }
    }

    view() {
        const time = Date.now() - (this.state.messages.display_time * 1000);
        const list = this.state.messages.messages.filter(message => message.time >= time);

        if (list.length === 0) {
            return nothing;
        }

        setTimeout(() => this.removeMessages(list), this.state.messages.display_time * 1000);

        return html`
            <div class='message-wrapper'>
                ${list.map(m => this.renderMessage(m))}
            </div>
        `
    }

    renderMessage = message => {
        const messageClass = message.type === 'Error' ? 'error' : 'info';
        return html`
            <div class='message-header ${messageClass}'>
                <div class='message-content'>
                    <span class='message-text'>${message.type}: ${message.text}</span>
                </div>
                <button class='close-button' @click=${() => this.removeMessage(message)}>✖</button>
            </div>
        `;
    }

    removeMessage = message => {
        removeMessagesState([message]);
    }

    removeMessages = messages => {
        removeMessagesState(messages);
    }
}

customElements.define('kosyma-messages', Messages);
