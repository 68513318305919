import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/main.css'

class Main extends BElement {
    extractState(state) {
        return {
            session: state.matrix.session
        }
    }

    view() {
        return html`
            <kosyma-container class=${this.state.session ? 'container' : nothing}>
                <kosyma-content class=${this.state.session ? 'content' : nothing}></kosyma-content>
            </kosyma-container>
        `;
    }
}

customElements.define('kosyma-main', Main);
