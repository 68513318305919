import BElement from '../../BElement.js';
import {html} from 'lit-html';
import '@fortawesome/fontawesome-free/css/all.css';

class Icon extends BElement {
    view() {
        const icon = this.getAttribute('icon');

        return html`
            <i class='fa-solid fa-xl fa-${icon}'>
        `;
    }
}

customElements.define('kosyma-icon', Icon);