import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';

class RoomChatReply extends BElement {
    extractState(state) {
        return {
            room: state.rooms.room,
            chat: state.rooms.room.chat,
        }
    }

    view() {
        return html`
            <div class='input-group'>
                <input type='text' placeholder='Type a message...'
                       autofocus='"autofocus'
                <button>Send</button>
            </div>
        `;
    }

}

customElements.define('kosyma-room-chat-reply', RoomChatReply);