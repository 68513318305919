export const registerByOrgAdmin = async (baseUrl, request) => {
    return await fetch(baseUrl + '/admin/v1/users/matrixuser',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request)
        });
}