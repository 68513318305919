import {html} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'
import '../css/header.css'
import logo from '../../assets/kosyma-logo.png'
import {resetState} from '../../matrix/control/MatrixControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

class Header extends BElement {
    extractState(state) {
        return {
            session: state.matrix.session
        }
    }

    view() {
        return html`
            <header>
                <div class='logo'><img alt='logo' src=${logo}></div>
                ${this.state.session && html`
                    <nav>
                        <a @click=${() => Router.go('/messenger/')}>Home</a>
                        <a id='create-room' @click=${() => Router.go('/messenger/rooms/create')}>Create Room</a>
                        <a id='room-list' @click=${() => Router.go('/messenger/rooms/')}>Rooms</a>
                        <a @click=${() => Router.go('/messenger/devices/list')}>Devices
                            <kosyma-icon icon='gear'></kosyma-icon>
                        </a>
                        <a id='logout' @click=${this.onLogout}>Logout</a>
                    </nav>
                    <div class='menu-toggle'>☰</div>`
                }
            </header>`
    }

    onLogout = () => {
        getMatrixClient().logout().then(res => {
            Router.go('/');
            resetState();
        }).catch(err => addErrorMessage(err));
    }

    toggleMenu = () => {
        const nav = document.querySelector('header nav')
        nav.classList.toggle('active')
    }
}

customElements.define('kosyma-header', Header)
