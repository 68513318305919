import {html} from 'lit-html'
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js'
import {resetState, setBaseUrlState, setChangeServerState} from '../control/MatrixControl.js';
import {getMatrixClient} from '../control/MatrixClient.js';


class ChangeServer extends BElement {
    connectedCallback() {
        super.connectedCallback();
        setChangeServerState({name: 'url', value: this.state.base_url})
    }

    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            change_server: state.matrix.change_server,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Change Server (${this.state.base_url})</h2>
                    <kosyma-input-text
                            identifier='url'
                            label='URL'
                            name='url'
                            autofocus='true'
                            value=${this.state.change_server.url}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-button
                            identifier='change'
                            label='Change'
                            @click=${() => this.onChange()}
                    ></kosyma-button>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setChangeServerState({name, value});
    }

    onChange = () => {
        setBaseUrlState(this.state.change_server.url);
        resetState();
        if (getMatrixClient()) {
            getMatrixClient().logout().then(res => console.log('res'));
        }
        Router.go('/login')
    }
}

customElements.define('kosyma-change-server', ChangeServer)
