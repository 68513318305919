import {html} from 'lit-html';
import '../css/components.css';
import BElement from '../../BElement.js';

class Checkbox extends BElement {
    view() {
        const label = this.getAttribute('label');
        const name = this.getAttribute('name');
        const identifier = this.getAttribute('identifier');
        const checked = this.getAttribute('checked')?.toLowerCase?.() === 'true';

        return html`
            <div class='form-group'>
                <input
                        class='form-checkbox'
                        type='checkbox'
                        id='${identifier}'
                        name='${name}'
                        .checked=${checked}
                        aria-label='Checkbox für für ${label}'
                />
                <label class='form-label-checkbox' for='${identifier}'>
                    ${label}
                </label>
            </div>
        `;
    }
}

customElements.define('kosyma-checkbox', Checkbox);



