import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

export const getDevices = async () => {
    const {devices} = await getMatrixClient().getDevices();

    return await Promise.all(
        devices.map(async (device) => {
            const isVerified = await getMatrixClient().checkIfOwnDeviceCrossSigned(device.device_id);
            return {
                display_name: device.display_name,
                user_id: device.user_id,
                device_id: device.device_id,
                is_verified: isVerified
            };
        })
    );
};