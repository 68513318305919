import {html} from 'lit-html';
import BElement from '../../BElement';

class DeviceVerification extends BElement {

    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                Start verfication
            </div>
        `;
    }

    startVerification = async () => {
        for (const verificationRequest of globalThis.verificationRequests) {
            const hasVerificationReadyEvent = [...verificationRequest.eventsByThem.keys()]
                .some(eventByThem => eventByThem === 'm.key.verification.ready');

            if (hasVerificationReadyEvent) {
                await verificationRequest.startVerification('m.sas.v1');
            }
        }
    }
}

customElements.define('kosyma-device-verification', DeviceVerification);